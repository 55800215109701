import React from "react";
import Link from "next/link";
import LogoArrow from "../components/icons/Arrow.svg";

const Custom404 = () => {
  return (
    <>
      <main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-brand-blue-dark">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link href="/">
              <a className="inline-flex items-center px-5 py-2.5 space-x-2 font-medium transition-colors duration-300 rounded-md md:px-6 md:py-3 text-brand-blue-dark bg-brand-blue-lightest hover:bg-brand-green-dark hover:text-white">
                <span>Go Back Home</span>
                <LogoArrow className="w-5 h-5 transition-transform duration-300 ease-in-out group-hover:translate-x-1 mt-0.5 lg:w-6 lg:h-6" />
              </a>
            </Link>
            {/* <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
            <a href="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default Custom404;
